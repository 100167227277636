import Mock from "../mock";

const database = {
  information: {
    name: 'Carrie Lee',
    aboutContent: "I'm a product design leader with proven success delivering human-centered solutions across fintech, medtech, and retail analytics through data-driven design. I have a demonstrated track record of increasing user satisfaction and product efficiency through data analysis, user-centered design and content strategy for B2B and B2C applications on both desktop and mobile.",
    age: '',
    voice: '312.869.2856',
    country: 'USA',
    language: 'English',
    email: 'cal@calee.me',
    address: 'Joliet, Illinois',
    freelanceStatus: 'January 2025',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/carrieleedesigns/',
      dribbble: '',
      github: 'https://github.com/calee1/'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/LeeCA-resume.pdf'
  },
  services: [
    {
      title: "Design",
      icon: 'pallet',
      details: "Product design including wireframing, prototyping, content strategy and management as well as requirement gathering, competitive and user research and usability testing."
    },
    {
      title: "Data Analysis",
      icon: 'user',
      details: "Bringing data from raw to ready - data gathering, cleansing, processing, manipulating, visualizing and interpreting. Python analytics beginner with NumPy and Pandas."
    },
    {
      title: "Development",
      icon: 'code',
      details: "Front-end coding including design tokens, HTML, CSS, JavaScript as well as C#, SQL and XML in Agile environment. Beginner with Grafana, React and Node.js."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Carrie has been a pleasure to work with and a solid contribution to the team, even with swiftly changing requirements and demanding partners.",
      author: {
        name: 'Mary Henick',
        designation: 'Product owner'
      }
    },
    {
      id: 2,
      content: "Carrie has a great depth of understanding around designing UX. She cares deeply about the user's needs and works hard to make tasks easier.",
      author: {
        name: 'Steve Travis',
        designation: 'Former manager'
      }
    },
    {
      id: 3,
      content: "I would highly recommend Carrie to any team looking to get a clean expression of the user experience for their product",
      author: {
        name: 'Mitch Shiver',
        designation: 'Former manager'
      }
    },
    {
      id: 4,
      content: "Teaming up with Carrie and having her as a lead has been a great learning experience for my professional career. One of her greatest assets is her empathy.",
      author: {
        name: 'Maria Laura Ponti',
        designation: 'Direct report'
      }
    }
  ],
  skills: [
    {
      title: "Figma, Axure, Sketch, InVision",
      value: 95
    },
    {
      title: "Adobe Suite, G Suite, MS Suite",
      value: 95
    },
    {
      title: "Miro, Mural, Notion, Confluence, SharePoint",
      value: 90
    },
    {
      title: "HTML5, CSS3",
      value: 90
    },
    {
      title: "Python, NumPy, Pandas",
      value: 60
    },
    {
      title: "JavaScript, jQuery, node.js, ReactJS",
      value: 60
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Engineering Platform",
      subtitle: "Led app-centric design effort for an internal development platform from initial discovery and wireframing through usability testing and detailed specification writing for pixel-perfect UI implementation. (2024)",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: [
        "/images/portfolio-image-1-lg.jpg",
        "/images/portfolio-image-1-lg2.jpg",
        "/images/portfolio-image-1-lg3.jpg"
      ]
    },
    {
      id: 2,
      title: "Retail Data Analytics",
      subtitle: "Brought design thinking and leadership to a large grocery chain's data anlytics effort in exporting loyalty data, as well as reimagining the user experience around creating product groups and assigning product attributes. (2024)",
      imageUrl: "/images/portfolio-image-2.jpg",
      largeImageUrl: [
        "/images/portfolio-image-2-lg.jpg",
        "/images/portfolio-image-2-lg2.jpg",
        "/images/portfolio-image-2-lg3.jpg"
      ]
    },
    {
      id: 3,
      title: "Expense Management",
      subtitle: "Working as design lead at an HR services company, I modernized an expense reporting application with streamlined workflows for both company administrators and employees on both desktop and mobile. (2023) ",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: [
        "/images/portfolio-image-3-lg.jpg",
        "/images/portfolio-image-3-lg2.jpg",
        "/images/portfolio-image-3-lg3.jpg"]
    },
    {
      id: 4,
      title: "Consumer Banking",
      subtitle: "Project for new, mobile-first design pattern - a bottom drawer with a consistent call to action for cross-usage across divisions of the bank's public site starting with Home Lending. (2021)",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: ["/images/portfolio-image-4-lg.jpg"]
    },
    {
      id: 5,
      title: "Enterprise SaaS",
      subtitle: "Project to design and test a reusable pattern library to be used across a suite of enterprise software products for email security and brand management including data analysis. (2019)",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: [
        "/images/portfolio-image-5-lg.jpg",
        "/images/portfolio-image-5-lg2.jpg"]
    },
    {
      id: 6,
      title: "Radiology",
      subtitle: "Project for FDA-approved, web-based, radiology software. From market-based needs research to usability testing and detailed specifications for final, development and testing. (2016)",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      subtitle: "You can also contact me for more detailed, non-public work samples.",
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2024",
        position: "Lead Design Consultant",
        company: "Chicagoland, IL",
        details: "Currently working as UX design consultant, leading end-to-end design projects with full-stack design from user research to user acceptance and everything in between. "
      },
      {
        id: 2,
        year: "2022 to 2023",
        position: "Lead UX Designer",
        company: "TriNet, Dublin, CA",
        details: "Successfully led a cross-functional team to deliver outstanding UX for the company's financial services portfolio; including responsive web and native mobile applications (iOS and Android) for Expense Management, Payroll and Time & Attendance."
      },
      {
        id: 3,
        year: "2022",
        position: "Senior UX Designer",
        company: "Kentik, San Jose, CA",
        details: "Led end-to-end UX activities for Alerting, Trends, Insights and Data Shares for the Core module of a B2B network observability platform."
      },
      {
        id: 4,
        year: "2019 to 2021",
        position: "Lead UX Designer",
        company: "Wells Fargo, Oakland, CA",
        details: "I led design activities for a new mobile-first design system pattern for home loan applications as well as streamlined workflows for claims submissions utilizing machine-learning and AI."
      },
      {
        id: 5,
        year: "2018",
        position: "Senior UX Designer",
        company: "Valimail, San Francisco, CA",
        details: "Design lead for the initial design system and suite of products for a start-up focusing on enterprise-level, on-demand, anti-phishing, brand protection, and compliance software-as-a-service."
      },
      {
        id: 6,
        year: "2014 to 2017",
        position: "Senior Interaction Designer",
        company: "Hologic, Sunnyvale, CA",
        details: "Worked as senior designer and UX team lead on end-to-end design for generative, FDA-approved medical instrumentation, and companion web-based software for radiologists. "
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "Chicago, IL",
        graduation: "Certified Data Analyst",
        university: "Udemy",
        details: "In Progress"
      },
      {
        id: 2,
        year: "San Francisco, CA",
        graduation: "Certified User Experience Analyst",
        university: "Human Factors International",
        details: "2015"
      },
      {
        id: 3,
        year: "Chicago, IL",
        graduation: "M.S., Human-Computer Interaction",
        university: "DePaul University",
        details: ""
      },
      {
        id: 4,
        year: "Peoria, IL",
        graduation: "B.S., Communication & Psychology",
        university: "Bradley University",
        details: ""
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Sample',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "1",
      createMonth: 'January',
      createYear: "2024"
    },
  ],
  contactInfo: {
    phoneNumbers: ['312.869.2856'],
    emailAddress: ['cal@calee.me'],
    address: "Joliet, Illinois"
  }
}

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
